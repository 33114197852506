/* eslint-disable */
// eslint conflicts in auto vue create config
import Vue from "vue";
import axios from "axios";
import ROUTE from "~/constants/routes";
import router from "~/router";
// import keysToCamelCase from "camelcase-keys";
// import keysToSnakeCase from "snakecase-keys";
import { getAccessToken, logOut, setTokenData } from "~/helpers/userAuth";
// import { getSiteKey } from "~/helpers/userAuth";

const lang = localStorage.getItem("language") || "de";

const config = {
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Content-Language": lang,
  },
  // baseURL: "https://api.simple.test.ut.in.ua",
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  (config) => {
    //Authorization

    if (getAccessToken()) {
      config.headers.Authorization = `Bearer ${getAccessToken()}`;
    }

    //User id

    // if (config.data) {
    //   config.data = keysToSnakeCase(config.data, { deep: true });
    // }

    return config;
  },
  // Do something before request is sent f
  (error) => {
    if (error.response.status === 401) {
      logOut();
    }
    Promise.reject(error);
  },
);

// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    if (response.data) {
      // response.data = keysToCamelCase(response.data, { deep: true });
    }
    return response;
  },
  async (error) => {
    const originalConfig = error.config;

    if (error.response && error.response.data) {
      // error.response.data = keysToCamelCase(error.response.data, { deep: true });
    }

    return Promise.reject(error);
  },
);

Plugin.install = function (Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
